@font-face {
    font-family: 'Azkidenz';
    src: url("./assets/fonts/chase/Akzidenz.otf") format('opentype');
}

@font-face {
    font-family: 'Univers LT Ultra-Condensed';
    src: url("./assets/fonts/chase/Univers LT Std 59 Ultra Condensed.otf") format('opentype');
}
@font-face {
    font-family: 'Univers LT Condensed';
    src: url("./assets/fonts/chase/Univers 47 Condensed Light.otf") format('opentype');
}

@font-face {
    font-family: 'Univers Black';
    src: url("./assets/fonts/chase/UniversLTStd Black.otf") format('opentype');
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    padding: 0;
    margin: 0;
    touch-action: none;
}

.joypad-container {
    position: fixed;
    bottom: 20px; /* Adjust the position to bottom for a better placement */
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between; /* Space out the D-pad and action buttons */
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 100%;
}
.dpad-container {
    position: absolute;
    bottom: 0; /* Moves D-pad to the bottom */
    left: 0; /* Aligns D-pad to the left */
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .dpad-container .button {
        position: absolute;
        background-color: lightgray;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        cursor: pointer;
        transition: background-color 0.2s ease;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        user-select: none;
    }

    /* D-pad button size */
    .dpad-container #up,
    .dpad-container #down {
        width: 85px;
        height: 130px;
    }

    .dpad-container #left,
    .dpad-container #right {
        width: 130px;
        height: 85px;
    }

    /* Position buttons correctly */
    .dpad-container #up {
        top: 0;
    }

    .dpad-container #down {
        bottom: 0;
    }

    .dpad-container #left {
        left: 0;
    }

    .dpad-container #right {
        right: 0;
    }

/* A & B Buttons */
.action-buttons {
    position: absolute;
    bottom: 0; /* Align at the bottom */
    right: 0; /* Align to the right */
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

    .action-buttons .button {
        width: 100px; /* Bigger buttons */
        height: 100px;
        border-radius: 50%;
        font-size: 40px;
    }

    .action-buttons #a {
        background-color: #ff5555; /* Red for A */
    }

    .action-buttons #b {
        background-color: #ffbb55; /* Yellow for B */
    }

/* Button active state */
.button.active {
    background-color: lightgreen;
}


.button {
    width: 80px;
    height: 80px;
    background-color: lightgray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Prevents text selection */
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
}