.AnswerButton {
  padding: 0;
  width: 100%;
  height: 33%;
  border-radius: 10px;
  border: 4px black solid;
  outline: none; 
  color: white;
  background: linear-gradient(#00A8C6 0%, #007E82 10%, #0182B1 15%, #015068 30%, #00273B 45%, #007E82 80%, #1882A4 90%,#00A8C6 100%);
  box-shadow: inset 0 0 5vh #00A8C6;
  position: relative;
  
  .AnswerButtonReflection{
    width: 100%;
    height: 25%;
    background: radial-gradient(#ffffff99 10%, #00000000 40%  );
    position: absolute;
    top: 0;
    z-index: 1;
  }
  
  .LabelContainer{
    padding: 2vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;

    .AnswerLetter{
      font-size: 3.5em;
      padding-right: 1.5vh;
    }

    .AnswerText{
      font-size: 2.5em;
      font-family: Azkidenz;
    }
  }
}

.AnswerButton:active {
  background: linear-gradient(#7AD0E8, #37C6F4, #7AD0E8);
  box-shadow: inset 0 0 5vh #7AD0E8;
  
}