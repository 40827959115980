.Wrapper {
  
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 75%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  max-width: 400px;
    margin-bottom:7vh;
  
  .WhiteBorder{
    width: 100%;
    height: 100%;
    background: #F1F2F2;
    padding:0.5vh;
    border-radius: 3vh;
    box-sizing: border-box;
    
    .BlueBorder{

      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #046284, #1882A4 3%, #046284 12%, #44A2C9 31%, #44A2C9 61%, #076285 68%, #046284 81%, #046284 100%);
      padding: 0.5vh;
      border-radius: 2.5vh;
      box-sizing: border-box;
      
      .MetallicGradient{

        width: 100%;
        height: 100%;
        padding: 0.5vh;
        border-radius: 2vh;
        box-sizing: border-box;
        background: radial-gradient(#DEE7EE 0%, #3F5A6C 12%, #DCE5EC 21%, #8FA6B7 31%, #CDD9E2 37%, #E3EBF1 61%, #415E70 73%, #6E8696 81%, #DEE7ED 90%, #374F5F 100%);
        
        .InnerGradient{
          width: 100%;
          height: 100%;
          
          border: 4px solid #210202;
          
          border-radius: 1.75vh;
          padding: 0.5vh;
          box-sizing: border-box;
          align-items: center;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          &.Blue {
            background: linear-gradient(#065DA9 9%, #272A64 33%, #272E6A 65%, #13133D 100%);
          }
          &.Red{
            background: linear-gradient(#D32E27 11%, #991C21 40%, #991C21 55%);
          }
          
          .Heading{
            color: white;
            font-family: Azkidenz, sans-serif;
            font-size: 2.1em;
            margin:1vh;
            text-align: center;
          }
          
          .SubHeading{
            color: white;
            font-family: Azkidenz;
            font-size: 1.5em;
            margin:1vh;
            text-align: center;
          }
        }
       
        }
        
        
      }
    }
  }
