.RecordButtonContainer{
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  padding: 1vh;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;

  .RecordButton_MetallicRed {
    
    width: 100%;
    height: 100%;
    background: linear-gradient(to right , #991C21 0%, #D33B4B 3%, #D32E27 12%, #D33B4B 31%, #991C21 61%, #D33B4B 70%, #D32E27 79%, #991C21 100%);
    border-radius: 50%;
    border: none;
    position: relative;
    z-index: 1;
    
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 3s ease-out;
    overflow: hidden;
    
    .RecordButton_MetallicSilver {
      background: radial-gradient(#DEE7EE 0%, #3F5A6C 12%, #DCE5EC 21%, #8FA6B7 31%, #CDD9E2 37%, #E3EBF1 61%, #415E70 73%, #6E8696 81%, #DEE7ED 90%, #374F5F 100%);
      border-radius: 50%;
      width: 100%;
      height: 100%;
      padding: 0.8vh;
      box-sizing: border-box;
      box-shadow: black 0 0 20px;
      
      .RecordButton_WhiteBorder {

        width: 100%;
        height: 100%;
        background: #E3EBF1;
        z-index: 3;
        border-radius: 50%;
        padding: 0.5vh;
        box-sizing: border-box;

        .RecordButton_InnerGradient {
          width: 100%;
          height: 100%;
          background: linear-gradient(#D32E27 11%, #991C21 40%, #991C21 55%);
          border: 4px solid #210202;
          z-index: 3;
          border-radius: 50%;
          padding: 0.5vh;
          box-sizing: border-box;
          box-shadow: inset 0 0 20px rgba(255, 150, 150, 0.8);
          transition: background 0.2s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
                    
          .Icon {
            width: 50%;
            height: 50%;
            pointer-events: none;
            
          }
          
        }

        .RecordButton_InnerGradient.pressed {
          background: linear-gradient(#D32E27 11%, #991C21 40%, #991C21 55%);
          box-shadow: inset 0 0 20px rgba(131, 22, 22, 0.8);
        }
      }
    }
  }
  
  .RecordButton_MetallicRed.pressed{
    
  }
  
 
}



