body {
    -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
    -webkit-user-select: none;
}

* {
    font-family: 'Poppins', sans-serif;
    touch-action: manipulation;
    -webkit-touch-callout: none;
}
button {
    font-family: 'Poppins', sans-serif;
    user-select: none;
}
body {
    background-color: #6195AB;
}
.new-controller-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    user-select: none;
    overflow: hidden;
    position: fixed;
    box-sizing: border-box;
    background: url(../../assets/background.png) repeat;
    background-size: cover;
    background-position: center;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
    pointer-events: none;
    -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
    -webkit-user-select: none;
}

.debug-info {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-size: 0.8vw;
    opacity: 0.5;
    color: black;
}

.initial-position {
    position: absolute;
    height: 15vw;
    aspect-ratio: 1/1;
    transform: translate(-50%,-50%);
    background-color: #D9C2CD;
    border: 1px #556 solid;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;

    text {
        margin: 0;
        color: white;
    }
}

.current-position {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    max-width: 50px;
    max-height: 50px;
    aspect-ratio: 1/1;
    transform: translate(-50%,-50%);
    background-color: #3EEEBCaa;
    border: 1px #3EEEBC solid;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;

    text {
        margin: 0;
    }
}

.deadzone {
    position: absolute;
    width: 3vw;
    aspect-ratio: 1/1;
    transform: translate(-50%,-50%);
    background: rgba(255, 78, 149, 0.22);
    border: 1px rgba(255, 78, 149) solid;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;

    text {
        margin: 0;
        color: white;
    }
}


.joystick-container {
    width: 30vw;
    aspect-ratio: 1/1;
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translateY(-50%);
    border: 1px #556 solid;
    border-radius: 100%;
}


.buttons-wrapper {
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    width: 40vw;

    .buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vw;
        background-color: #878394;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        transform: rotate(-45deg);
        border: 2px #556 solid;

        .buttons-row {
            display: flex;
            width: 60%;
            justify-content: space-between;
            padding: 1vw 0.5vw 1vw 1vw;
            background-color: #dccbd1;
            border-radius: 1000px;
            box-sizing: border-box;
            border: 2px #556 solid;

            button {
                margin-top: -10px;
                transform: rotate(45deg);
                width: 10vw;
                height: 10vw;
                border-radius: 50%;
                border: none;
                font-weight: 600;
                font-family: 'Poppins', sans-serif;
                font-size: 4vw;
                text-shadow: 5px 5px 0px #00000022;
                position: relative;
                transition: all 0.05s ease-out;

                &:active {
                    box-shadow: none;
                    transform: translate( -3px, 5px) rotate(45deg);
                }

             
            }

            .A-button {
                background-color: #FF4E95;
                color: #FF97C1;
                box-sizing: border-box;
                box-shadow: 0px 10px 0px #CB3B75;
                border-top: 10px solid #FF7BB0;
            }

            .B-button {
                background-color: #FFFE4E;
                color: #FFFEA7;
                box-sizing: border-box;
                box-shadow: 0px 10px 0px #CECD32;
                border-top: 10px solid #FFFEA7;
            }

            .X-button {
                background-color: #3D91FF;
                color: #AACFFF;
                box-sizing: border-box;
                box-shadow: 0px 10px 0px #296DC6;
                border-top: 10px solid #71AEFF;
            }

            .Y-button {
                background-color: #3EEEBC;
                color: #ACFFE8;
                box-sizing: border-box;
                box-shadow: 0px 10px 0px #35C199;
                border-top: 10px solid #80FFDB;
            }
        }
    }
}

.menu-buttons {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 15vw;
    left: 50%;
    bottom: 40%;
    transform: translate(-80%, 100%);

    .menu-button {
        display: flex;
        flex-direction: column;
        width: 40%;
        justify-content: center;
        align-items: center;

        button {
            height: 15vh;
            width: 5vh;
            transform: rotate(45deg);
            background: linear-gradient(#414141 0%, #1d1d23 100%);
            border-radius: 10vh;
            border: none;
            outline: none;
            box-shadow: 5px 5px 0px #000000cc;
            transition: all 0.1s ease;

            &:active {
                box-shadow: none;
                transform: translateY(5px) rotate(45deg);
            }

            
        }
    }
}



// FOR CONTROLLER 2
.position-dot {
    position: absolute;
    z-index: -1;
    width: 1vw;
    aspect-ratio: 1/1;
    transform: translate(-50%,-50%);
    background-color: #3eeebc;
    border-radius: 50%;
    opacity: 0.1
}


.dpad-container {
    position: absolute;
    width: 45vh;
    height: 45vh;
    transform: translateY(-50%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    transform: rotate(45deg) translateY(-50%);
    left: 2%;
    top: 50%;
    gap: 1vh;
    pointer-events: all;


    .buttonActive {

        
    }

    button {
        height: 100%;
        aspect-ratio: 1/1;
        border: none;
        padding: 0;
        outline: none;
        background-color: transparent !important;
        box-sizing: border-box;

        position: relative;
        .block-img {
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            user-select: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            image-rendering: pixelated
        }

        .arrow-img {
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            user-select: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            position: absolute;
            top: 0;
            left: 0;
            image-rendering: pixelated
        }
    }
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
    pointer-events: none;

}
.settings-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(10px);
    inset: 0;
    pointer-events: all; // Ensure the modal captures all pointer events

    .settings-modal-content {
        width: 30vw;
        height: 30vh;
        background-color: #156385;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vw;
        padding: 3vh;
        box-sizing: border-box;
        border-radius: 2vh;
        pointer-events: auto; // Allow pointer events on the modal content

        button {
            width: 100%;
            height: 100%;
            background-color: #FF4E95;
            border: none;
            border-radius: 10px;
            color: white;
            font-size: 1.5vw;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.1s ease;

            &:active {
                transform: translateY(5px);
            }
        }

        div {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 1vw;
            height: 100%;
        }
    }
}

.settings-button {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 7vw;
    height: 7vw;
    background-color: transparent;
    border: none;
    z-index: 1000;
    pointer-events: all;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    
    }

    &:active {
        transform: translateY(5px);
    }
}

.bomb-button-container {
    display: flex;
    width: 25vw;
    aspect-ratio: 1/1;
    position: absolute;
    right: 10vw;
    bottom: 18%;
    pointer-events: all;

    button {
        width: 100%;
        aspect-ratio: 1/1;
        transform: rotate(-45deg);
        position: relative;
        box-sizing: border-box;
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;

        &:active {
            transform: rotate(-45deg);
        }

        .block-img {
            z-index: 0;
            width: 100%;
            height: 100%;
            image-rendering: pixelated
        }

        .bomb-img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            z-index: 1;
            width: 50%;
        }
    }
}

* {
    /* Remove default styling */
    -webkit-appearance: none;
    appearance: none;
    /* Remove the grey highlight on tap in iOS */
    -webkit-tap-highlight-color: transparent;
    /* Remove focus outline if needed */
    outline: none;
}

/* Also remove inner focus border for WebKit browsers */
*::-webkit-focus-inner {
    border: 0;
}




.joining-container {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;  
    flex-direction: row;
    justify-content: center;
    align-items:center;

    button {
        width: 30%;
        height: 30%;
        background-color: transparent;
        outline: none;
        border: none;
        
        &:active {
            transform: translateY(5px);
        }

        img {
            width: 100%;
            object-fit: cover;
            image-rendering: pixelated;
        }
    }
}