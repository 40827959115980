.Background {
    background-color: #06070B;
    background-size: 25px 25px, 100% 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 0;
    position: absolute;
    transition: opacity 0.3s ease-in;
    width: 100vw;
  
  &.Blue{
    background-image: radial-gradient(circle, #1a0fb0 50%, transparent 1%);
  }
  
  &.Red{
    background-image: radial-gradient(circle, #b70000 50%, transparent 1%);
    opacity: 0;
  }
  
  &.Red:before{
    background-image: radial-gradient(circle, #b70000 50%, transparent 1%);
  }
  
  &.Show{
    opacity: 1;
  }
}

.Background:before {
  content: "";
  position: absolute;
  inset:0;
  background-image: radial-gradient(circle, #065DA9 50%, transparent 1%);
  background-size: 25px 25px, 100% 100%;
  opacity: 0;
  z-index: -1;
  animation: gradientFade 5s infinite alternate;
  transition: opacity 1s ease-in-out;
 }

@keyframes gradientFade {
  0% { opacity: 0;  }
  100% {opacity: 1; }
  
}

.Vignette{
  position: absolute;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(45deg, black, transparent, black);
}

.ChaseController {
  padding: 2vh;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden !important;
  z-index: 0;
  position: relative;
  
  .Header{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .Logo{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: 5vh;
      margin-bottom: 7.5vh;
      max-width: 250px;
    }
  }
  
  .HeadingView{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2vh;
    width: 100%;
    box-sizing: border-box;
    max-width: 500px;
    position: relative;
  }
  
  .WaitingView {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2vh;
    width: 100%;
    box-sizing: border-box;
    max-width: 500px;
    position: relative;
  }
  
  .AnswerButtonsWrapper {
    
    margin: auto ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 30vh;
    padding-top: 2vh;
    box-sizing: border-box;
    position: relative;
    max-width: 500px;
    margin-bottom:10vh;
    
    .AnswerButtons_WhiteBorder {
        width: 100%;
        height: 100%;
        background: #F1F2F2;
        z-index: 3;
        padding: 0.5vh;
        border-radius: 3vh;
        box-sizing: border-box;
      
      .AnswerButtons_BlueBorder{
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #046284, #1882A4 3%, #046284 12%, #44A2C9 31%, #44A2C9 61%, #076285 68%, #046284 81%, #046284 100%);
        z-index: 4;
        padding: 0.5vh;
        border-radius: 2.5vh;
        box-sizing: border-box;
        
        .AnswerButtons_MetallicGradient{
          width: 100%;
          height: 100%;
          z-index: 5;
          padding: 0.5vh;
          border-radius: 2vh;
          box-sizing: border-box;

          background: radial-gradient(#DEE7EE 0%, #3F5A6C 12%, #DCE5EC 21%, #8FA6B7 31%, #CDD9E2 37%, #E3EBF1 61%, #415E70 73%, #6E8696 81%, #DEE7ED 90%, #374F5F 100%);
          
          .AnswerButtons_InnerGradient{
            width: 100%;
            height: 100%;
            background: linear-gradient(#065DA9 9%, #272A64 33%, #272E6A 65%, #13133D 100%);
            border: 4px solid #210202;
            z-index: 6;
            border-radius: 1.75vh;
            padding: 0.25vh;
            box-sizing: border-box;
            align-items: center;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            
          }
        }
      }
    }
  }
  
  .RecorderWrapper {
    
    margin: auto;
    padding:0vh;
    
    width: min(50vw,30vh);
    height: min(50vw,30vh);
    
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    
  }

  .RoomStatusContainer {
   
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    
    
    .RoomStatus_WhiteBorder{

      width: 100%;
      height: 100%;
      background: #F1F2F2;
      padding-top:0.5vh;
      
      box-sizing: border-box;
      
      .RoomStatus_BlueBorder{

        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #046284, #1882A4 3%, #046284 12%, #44A2C9 31%, #44A2C9 61%, #076285 68%, #046284 81%, #046284 100%);
        padding-top: 0.5vh;
        
        
        box-sizing: border-box;
        
        .RoomStatus_MetallicGradient{
          width: 100%;
          height: 100%;
          padding-top: 0.5vh;
          
          box-sizing: border-box;
          background: radial-gradient(#DEE7EE 0%, #3F5A6C 12%, #DCE5EC 21%, #8FA6B7 31%, #CDD9E2 37%, #E3EBF1 61%, #415E70 73%, #6E8696 81%, #DEE7ED 90%, #374F5F 100%);
                    
          .RoomStatus_InnerGradient{
            
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-top: 4px solid black;

            padding-top: 0.5vh;
            padding-bottom: 0.5vh;
            padding-left: 3vh;
            padding-right: 3vh;
            
            
            
            background: linear-gradient(#065DA9 9%, #272A64 33%, #272E6A 65%, #13133D 100%);
            box-sizing: border-box;

            .ConnectionStatusDot {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: red;
              transition: background-color 0.3s ease;
              box-shadow: red 0 0 10px;

              &.connected {
                background: radial-gradient(#00ff00, #63ff63);
                box-shadow: green 0 0 10px;
              }
            }
            
            .RoomInfoLabel{
              color: white;
                font-family: "Univers LT Condensed", sans-serif;
                font-size: 1.5em;
              padding: 0;
              margin:2.5vh;

            }
            
          }
        }
      }
    }
  }



}